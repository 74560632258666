<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div>
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Overtime
        </h1>
      </div>
    </div>
    <div class="px-3">
      <div class="w-full">
        <div class="mt-8">
          <Tab
            :tabs="['Planned Work', 'Overtime Pay']"
            border
            :active-tab="currentTab"
            @currentTab="currentTab = $event"
          />
        </div>
        <planned-work v-if="currentTab === 'Planned Work'" />
        <overtime-pay v-if="currentTab === 'Overtime Pay'" />
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import PlannedWork from "./plannedWork";
import OvertimePay from "./overtimePay";

export default {
  name: "AdvanceLoans",
  components: {
    Tab,
    PlannedWork,
    OvertimePay,
  },
  data() {
    return {
      currentTab: "Planned Work",
      showError: false
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
